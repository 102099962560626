import { YouTubePlaylist } from "@codesweetly/react-youtube-playlist";
import S from "./style";
import TranslateStatic from '../../locales/TranslateStatic'
import { Link } from "react-router-dom";
import { LocaleContext } from '../../locale-context';
export default function () {
  return (
    <S.Wrapper>
      <S.Sol>
        <a>
          <p>
            <b>
              <TranslateStatic string="Videolar" />
              {/* Youtube */}
            </b>
            {/* <TranslateStatic string="EgitimDestekLow2" /> */}
          </p>
        </a>

      </S.Sol>
      <S.Sag>
        <Link to="youtube" className="saydam">

          <div className="button"><TranslateStatic string="referancesMoreButton" /> </div>
        </Link>
        <LocaleContext.Consumer>
          {(value) => {
            if (value === "tr") {
              return <>
                <YouTubePlaylist
                  apiKey="AIzaSyB-bOMjkCW9qV8Pmy2CwMoPtoZUeQP0hdw"
                  playlistId="PLLTGKC6AlQ_LUQh0_EFUhnYn-aFfKlQaU"
                />
              </>
            }
            else {
              return <>
                <YouTubePlaylist
                  apiKey="AIzaSyB-bOMjkCW9qV8Pmy2CwMoPtoZUeQP0hdw"
                  playlistId="PLLTGKC6AlQ_J_9cuv7LtOEpIV70Bk0Mso"
                />
              </>
            }
          }}
        </LocaleContext.Consumer>

      </S.Sag>
    </S.Wrapper>
  );
}

