import styled from "styled-components";

const S = {
    Container:styled.div`
        padding:20px;
        overflow:hidden;
        box-sizing:border-box;
        iframe{
            overflow:hidden;
            padding:10px;
            width:100%;
            margin:10px;
            border-radius:20px;
            height:315px;
        }
    `,
    

  
}

export default S;