import styled from "styled-components";


const S = {
    chatcon: styled.div`
        width: 250px;
        padding:40px;
        position:fixed;
        right:0px;
        bottom:0px;
        z-index:11;
        display: flex;
        flex-direction: column;
        align-items: end;
        @media (max-width:648px){
            left:0px;
            right:auto;
            align-items: start;
        }
        .pop {
            width: 70px;
            height: 70px;
            cursor: pointer;
            border-radius:50%;
            overflow:hidden;
            img{
                width:70px;
                height:70px;
                border-radius: 50%;
                    
            }
            // img {
            //     border-radius: 50%;
            //     width: 100%;
            //     background:#35e233;
            //     animation: heartbeat 2s infinite;
            //     @keyframes heartbeat {
            //         0%
            //         {
            //             transform: scale( .75 );
            //         }
            //         20%
            //         {
            //             transform: scale( 1 );
            //         }
            //         40%
            //         {
            //             transform: scale( .75 );
            //         }
            //         60%
            //         {
            //             transform: scale( 1 );
            //         }
            //         80%
            //         {
            //             transform: scale( .75 );
            //         }
            //         100%
            //         {
            //             transform: scale( .75 );
            //         }
            //       }
               
            // }
        }
        .chat-box {
            /* display: none; */
            width: 100%;
            height: auto;
            border-radius: 25px;
            background-color: #eee;
            margin-bottom:20px;
            .header {
                background-color: #35e233;
                padding: 15px;
                border-radius: 20px 20px 0 0;
                color: #fff;
                font-size: 16px;
            }
            .msg-area {
                overflow: hidden;
                height: auto;
                padding: 15px;                
                a{
                    color:black;
                    display:flex;
                    flex-direction:row;
                    text-decoration:none;
                    align-items:center;
                    margin-bottom:20px;
                    img{
                        width:25px;
                        margin-right:10px;
                    }
                }
                .left {
                    span {
                        display: inline-block;
                        font-size: 17.5px;
                        border-radius: 15px;
                        padding: 15px;
                        background-color: #ddd;
                    }
                }
                .right {
                    text-align: right;
                    span {
                        display: inline-block;
                        font-size: 17.5px;
                        border-radius: 15px;
                        padding: 15px;
                        background-color: #ddd;
                    }
                }
            }
            .footer {
                padding: 0 20px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                input {
                    border: 1px solid #fff;
                    padding: 10px;
                    width: 80%;
                    border-radius: 15px;
                    &:foucs {
                        outline: none;
                    }
                }
                button {
                    border: none;
                    font-size: 22.5px;
                    color: lightgreen;
                    cursor: pointer;
                    }
            }
        }
    `
}
export default S
