import React from "react";
import S from './style';

import Egitim from '../../assets/egitimedestek.png';

import TranslateStatic from '../../locales/TranslateStatic'
import { Link } from "react-router-dom";
import MiniSlider from './miniSlider'

import WebinarV2 from '../webinarV2'
export default function () {
    return <S.Wrapper>


        <S.Sol>
            <a>
                <p>
                    <b>
                        <TranslateStatic string="Webinar" />
                    </b>
                    {/* <TranslateStatic string="EgitimDestekLow2" /> */}
                </p>
                {/* <article>
                Aralık 2015 - Mayıs 2024 tarihleri arasında 58 ilde, 59 köy okuluna, 58 adet kitaplık ve 12964 adet kitap desteği yapıldı.
                </article>
                <br/>
                <article>
                Mart 2007 yılından itibaren 23 öğrenciye burs imkanı sağlandı.
                </article> */}
            </a>

        </S.Sol>
        <S.Sag>
            <WebinarV2/>
            {/* <iframe width="100%" height="315" src="https://www.youtube.com/embed/BUuMuipbSAI?si=oUaJY3wff3MOtsnd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/iCb8i8jFrlw?si=uG-VHwOBCvbpH39I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/j4BoJCXBl-Y?si=2ndKAm146JOEZ9WF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/GH2rQHkS00I?si=s3XsdyI9ORk3TgqJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
            {/* <img src={Egitim}/> */}
        </S.Sag>
        <S.Dip>
            <Link to="./webinar"><TranslateStatic string="referancesMoreButton" /></Link>
        </S.Dip>
    </S.Wrapper>
}