
import { Link } from 'react-router-dom';
import S from './style'
import { LocaleContext } from '../../locale-context';
import TranslateStatic from '../../locales/TranslateStatic';
import YumurtlamaImg from '../../assets/endoskopik.jpeg'
export function Ilac() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <Link><TranslateStatic string="IlacAnlatimiBaslik" /></Link>
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <div className='banner'>
          <div className='baslik'>
            <h1>
              <TranslateStatic string="IlacAnlatimiBaslik" />
            </h1>
          </div>
        </div>
        <div className='content'>

          <div className='texts'>
            <p><TranslateStatic string="IlacAnlatimiMetin" /></p>

          </div>
          {/* <h3>360° Kliniğimiz</h3> */}

          <LocaleContext.Consumer>
            {(value) => {
              if (value === "tr") {
                return <>
                  <div className='mapContainer'>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/D2LrVl--QXk?si=a5v9CAaon8Elt1nl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/PgJRTXlm5hk?si=ofeBt7EQrdfYM0pk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/0dEpUkc6sfQ?si=xO8_JOl5uOS6725m" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/AV4f4aBhEZY?si=wrEy74qS1llRMOc8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ibApofPlwFg?si=QsO-by4mZQyhvc1_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/DBoqFh6U7yc?si=H1RoJRPhPMSBEeiq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/8g7C6YCSJAs?si=Ng6NsME9PoONkwjB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/0MRou2y9Y4E?si=FgKPmtw-dV8v4qvi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ULS0oOFqvNk?si=59_r-KMG8h-AGL4I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  
                  </div>
                </>
              }
              else if (value === "ru") {
                return <>
                  <div className='mapContainer'>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/-dRhSF8e7fM?si=Ycpbwm--LOTekBbB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/ci17xrnyvug?si=zXK3sGexkryw-aRW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/VFe39t31kR4?si=2Sb_lMzIjgtEzQVU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/OevnnFDNc2o?si=ODuYSc11xzf8ow7L" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/pD0X0DVfzxg?si=DRx2GbSvRt1LZ-PL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                  <iframe width="560" height="315" src="https://www.youtube.com/embed/4rv83V2rsyc?si=pxBYIuu6j9nOURnZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/XBgcR3IJaYU?si=ey6utjhyxvxySxKO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/Su8UhucLt9g?si=BiZf6Fo0r_Z2fHgC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                  <iframe width="560" height="315" src="https://www.youtube.com/embed/XXG6XYLEKFg?si=0wj0eOy82pRBiT9n" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
                </>
              }
              else if (value === "en") {
                return <>
                  <div className='mapContainer'>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/5beWrZABrEA?si=ESFSUmvi8CguADFw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/JTHngEVxt3o?si=fbmP0nYaLENDk8-s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/bRPNVbVaSsc?si=PjZCVKFkjDmrfWC-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/u-vm95hhhvg?si=fAKcYYQGfSwiLZGh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/Zf6FMpS5Njc?si=vMnKdgT2K-lPe75D" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                 
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/NkkvlAmug-Y?si=HTo3MNFluGrZ4Z8k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/Igreh2-pdhA?si=raOVw5jpU8V5Z8qx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/WJa4ZyGoacI?si=OOJTd315inZ-NQAG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
               
                  </div>
                </>
              }
              else if (value === "uz") {
                return <>
                  <div className='mapContainer'>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/-dRhSF8e7fM?si=Ycpbwm--LOTekBbB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/ci17xrnyvug?si=zXK3sGexkryw-aRW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/VFe39t31kR4?si=2Sb_lMzIjgtEzQVU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/OevnnFDNc2o?si=ODuYSc11xzf8ow7L" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/pD0X0DVfzxg?si=DRx2GbSvRt1LZ-PL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                  <iframe width="560" height="315" src="https://www.youtube.com/embed/4rv83V2rsyc?si=pxBYIuu6j9nOURnZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/XBgcR3IJaYU?si=ey6utjhyxvxySxKO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/Su8UhucLt9g?si=BiZf6Fo0r_Z2fHgC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                  <iframe width="560" height="315" src="https://www.youtube.com/embed/XXG6XYLEKFg?si=0wj0eOy82pRBiT9n" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
                </>
              }
              else if (value === "ar") {
                return <>
                  <div className='mapContainer'>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/-dRhSF8e7fM?si=Ycpbwm--LOTekBbB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/ci17xrnyvug?si=zXK3sGexkryw-aRW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/VFe39t31kR4?si=2Sb_lMzIjgtEzQVU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/OevnnFDNc2o?si=ODuYSc11xzf8ow7L" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/pD0X0DVfzxg?si=DRx2GbSvRt1LZ-PL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                  <iframe width="560" height="315" src="https://www.youtube.com/embed/4rv83V2rsyc?si=pxBYIuu6j9nOURnZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/XBgcR3IJaYU?si=ey6utjhyxvxySxKO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/Su8UhucLt9g?si=BiZf6Fo0r_Z2fHgC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                  <iframe width="560" height="315" src="https://www.youtube.com/embed/XXG6XYLEKFg?si=0wj0eOy82pRBiT9n" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
                </>
              }
            }}
          </LocaleContext.Consumer>
        </div>


      </S.Section>
    </S.Wrapper >
  );
}
