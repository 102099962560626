

import { References } from '../../components/references';
import S from './style';
import SliderV2 from '../../components/sliderv';
import FireExtin from '../../components/fireExtin';
import Whyus from '../../components/whyus';
import Treatments from '../../components/treatments';
import EgitimeDestek from '../../components/egitimeDestek';
import Webinar from '../../components/webinar';
import UremeGuclugu from '../../components/uremeGuclugu';
import YouTubeList from '../../components/youtube';
import WebinarV2  from '../../components/webinarV2'
export function HomePage() {
  return (
    <S.Wrapper>
      <S.SlideShow>
        <SliderV2 />
      </S.SlideShow>
      {/* <Whyus/> */}
      {/* <Treatments/> */}
      {/* <FireExtin/> */}
      {/* <UremeGuclugu/> */}
      {/* <References /> */}
      <EgitimeDestek/>
      <Webinar/>
      {/* <WebinarV2/> */}
      <YouTubeList/>
    </S.Wrapper>
  );
}
