
import { Link } from 'react-router-dom';
import S from './style'
import { LocaleContext } from '../../locale-context';
import TranslateStatic from '../../locales/TranslateStatic';
import YumurtlamaImg from '../../assets/endoskopik.jpeg'

import { YouTubePlaylist } from "@codesweetly/react-youtube-playlist";

export function Webinar() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > 
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <div className='banner'>
          <div className='baslik'>
            <h1>
              <TranslateStatic string="Webinar" />
            </h1>
          </div>
        </div>
        <div className='content'>

          <div className='texts'>
            <p><TranslateStatic string="IlacAnlatimiMetin" /></p>

          </div>
          {/* <h3>360° Kliniğimiz</h3> */}

          <LocaleContext.Consumer>
            {(value) => {
              if (value === "tr") {
                return <>
                  <YouTubePlaylist
                    apiKey="AIzaSyB-bOMjkCW9qV8Pmy2CwMoPtoZUeQP0hdw"
                    playlistId="PLLTGKC6AlQ_IZLcA7-MnDEA7LE7X5GQNW"
                  />
                  <div className='TSRM'>
                    <iframe  src="https://www.youtube.com/embed/iCb8i8jFrlw?si=uG-VHwOBCvbpH39I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <iframe  src="https://www.youtube.com/embed/j4BoJCXBl-Y?si=2ndKAm146JOEZ9WF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>

                </>
              }
              else {
                return <>
                  <YouTubePlaylist
                    apiKey="AIzaSyB-bOMjkCW9qV8Pmy2CwMoPtoZUeQP0hdw"
                    playlistId="PLLTGKC6AlQ_IZLcA7-MnDEA7LE7X5GQNW"
                  />
                </>
              }
            }}
          </LocaleContext.Consumer>
        </div>


      </S.Section>
    </S.Wrapper >
  );
}
