// import "./App.css";
import { useState,useEffect } from "react";
// import back from "./assets/back.svg";
// import next from "./assets/next.svg";
import Whatsappicon from '../../../assets/whatsappicon.png'
import InstaColor from '../../../assets/instagramColor.png'
import Youtube from '../../../assets/youtube.png'
// import Telephone from '../../../assets/telephone.png'
import Telephone from '../../../assets/telephone-call.png'
import S from "./style";
const imagesArray = [
 Whatsappicon,InstaColor,Telephone,Youtube
];

export default function(props) {
  const [activeImage, setActiveImage] = useState(0);

  useEffect(() => {
    const id= setInterval(() => {
      console.log(activeImage);
      goToNextImage()
    }, 3000);
    return () => clearInterval(id);
  });
  const goToNextImage = () => {
    if (activeImage === imagesArray.length - 1) {
      setActiveImage(0);
      return;
    }
    setActiveImage(activeImage => activeImage + 1);
  };

  const goToPreviousImage = () => {
    if (activeImage === 0) {
      setActiveImage(imagesArray.length - 1);
      return;
    }
    setActiveImage(activeImage - 1);
  };

  return (
    <S.main>
      <section onClick={props.onClick}>
        <div className="mask"></div>
        <div style={{ transform: `translate3d(${-activeImage * 100}%, 0, 0)` }}>
          {/* <img src={imagesArray[activeImage]} alt="" className="main-image"
          style={{ transform: `translate3d(${-activeImage * 100}%, 0, 0)` }}
          /> */}
           {imagesArray.map((sr, index) => (
          <img
            className="main-image"
            key={index}
            src={sr}
          />
        ))}
        </div>
      </section>
    </S.main>
  );
}
