
import { Link } from 'react-router-dom';
import S from './style'
import { LocaleContext } from '../../locale-context';
import TranslateStatic from '../../locales/TranslateStatic';
import YumurtlamaImg from '../../assets/endoskopik.jpeg'

import { YouTubePlaylist } from "@codesweetly/react-youtube-playlist";

export function Ameliyatlar() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> >
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <div className='banner'>
          <div className='baslik'>
            <h1>
              <TranslateStatic string="Ameliyatlar" />
            </h1>
          </div>
        </div>
        <div className='content'>

          <div className='texts'>
            <p><TranslateStatic string="IlacAnlatimiMetin" /></p>

          </div>
          {/* <h3>360° Kliniğimiz</h3> */}

          <LocaleContext.Consumer>
          {(value) => {
            if (value === "tr") {
              return <>
                <YouTubePlaylist
                  apiKey="AIzaSyB-bOMjkCW9qV8Pmy2CwMoPtoZUeQP0hdw"
                  playlistId="PLLTGKC6AlQ_KIPWIRerxLarRZXw00z1tp"
                />
              </>
            }
            else {
              return <>
                <YouTubePlaylist
                  apiKey="AIzaSyB-bOMjkCW9qV8Pmy2CwMoPtoZUeQP0hdw"
                  playlistId="PLLTGKC6AlQ_KIPWIRerxLarRZXw00z1tp"
                />
              </>
            }
          }}
        </LocaleContext.Consumer>
        </div>
       

      </S.Section>
    </S.Wrapper >
  );
}
