import React from "react";
import Slider from "react-slick";
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic'
function MultipleItems() {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return (
        <div className="slider-container">
            <Slider {...settings}>
                <S.Container>
                    <iframe src="https://www.youtube.com/embed/4ilukG0rCC8?si=0k53gVYh7TCw85mxl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </S.Container>
                
                <S.Container>
                    <iframe src="https://www.youtube.com/embed/iCb8i8jFrlw?si=uG-VHwOBCvbpH39I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </S.Container>
                <S.Container>
                    <iframe src="https://www.youtube.com/embed/j4BoJCXBl-Y?si=2ndKAm146JOEZ9WF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </S.Container>
                {/* <S.Container>
                    <iframe src="https://www.youtube.com/embed/hcq321kZ7ik?si=Q50SC0Ux-Qi3KDeg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </S.Container> */}
                {/* <S.Container>
                    <iframe src="https://www.youtube.com/embed/Hmjea_LScoQ?si=oSFWkBYeqoJSc01H" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </S.Container> */}
                {/* <S.Container>
                    <iframe src="https://www.youtube.com/embed/AiEZham2k8k?si=8KnVaORtJe5Hhznl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </S.Container> */}
                <S.Container>
                    <iframe src="https://www.youtube.com/embed/bmbnZ-MWY0w?si=9jGCup0QxYMOERTk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </S.Container>
                <S.Container>
                    <iframe src="https://www.youtube.com/embed/yrgocymffsQ?si=BxT3tIhLn-bKlf1n" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </S.Container>
                <S.Container>
                    <iframe src="https://www.youtube.com/embed/0Gm7L9vKEHI?si=5GtisIEOKWko1jm-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </S.Container>
                {/* <S.Container>
                    <iframe
                        src="https://www.youtube.com/embed/BUuMuipbSAI?si=oUaJY3wff3MOtsnd"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen>
                    </iframe>
                </S.Container> */}
            </Slider>
        </div>
    );
}

export default MultipleItems;
