import React, { Component } from "react";

import S from './style'
import TranslateStatic from '../../../locales/TranslateStatic'
class Carousel extends React.Component {
  constructor(props) {
    super(props);

    this.prevItem = this.prevItem.bind(this);
    this.nextItem = this.nextItem.bind(this);

    this.state = {
      counter: 0,
      timeout: setTimeout(this.nextItem, this.props.timeoutTime)
    };
  }

  prevItem() {
    var prevItem = this.state.counter - 1 < 0 ? this.props.items.length - 1 : this.state.counter - 1;

    clearTimeout(this.state.timeout);
    this.setState({
      counter: prevItem,
      timeout: setTimeout(this.nextItem, this.props.timeoutTime)
    });
  }

  nextItem() {
    var nextItem = this.state.counter + 1 < this.props.items.length ? this.state.counter + 1 : 0;

    clearTimeout(this.state.timeout);
    this.setState({
      counter: nextItem,
      timeout: setTimeout(this.nextItem, this.props.timeoutTime)
    });
  }

  render() {
    const items = this.renderItems(this.props.items);

    return (
      <S.carousel>
        {/* <S.carouselPrev onClick={this.prevItem}></S.carouselPrev> */}
        {items}
        {/* <S.carouselNext onClick={this.nextItem}></S.carouselNext> */}
      </S.carousel>
    );
  }

  renderItems(items) {
    return items.map((item, index) => this.renderItem(item, (this.state.counter === index)));
  }

  renderItem(item, current) {
    return (
      <CarouselItem {...item} current={current}></CarouselItem>
    );
  }
}

class CarouselItem extends React.Component {
  render() {
    return (
      <S.carouselItem className={(this.props.current ? 'current' : '')}>
        <img src={this.props.image} role="presentation" />
        {/* <video src="" autoPlay muted loop={true} role="presentation" /> */}
      </S.carouselItem>
    );
  }
}

const carouselData = {
  timeoutTime: 3000,
  items: [
    {
      key: 21,
      image: 'https://gelecektupbebek.com.tr/eski/Egitime-Destek-Projemiz/Bartin/1.jpeg',
      title: <TranslateStatic string="sliderTitle" />,
      caption: <TranslateStatic string="sliderCaption" />,
    },

    {
      key: 22,
      image: 'https://gelecektupbebek.com.tr/eski/Egitime-Destek-Projemiz/Bartin/2.jpeg',
      title: <TranslateStatic string="sliderTitle" />,
      caption: <TranslateStatic string="sliderCaption" />,
    },
    {
      key: 4,
      image: 'https://gelecektupbebek.com.tr/eski/Egitime-Destek-Projemiz/Edirne/img/1.jpg',
      title: <TranslateStatic string="sliderTitle" />,
      caption: <TranslateStatic string="sliderCaption" />,
    },
    {
        key: 5,
        image: 'https://gelecektupbebek.com.tr/eski/Egitime-Destek-Projemiz/Edirne/img/2.jpg',
        title: <TranslateStatic string="sliderTitle" />,
        caption: <TranslateStatic string="sliderCaption" />,
      },
    {
        key: 6,
        image: 'https://gelecektupbebek.com.tr/eski/Egitime-Destek-Projemiz/Zonguldak/img/2.JPG',
        title: <TranslateStatic string="sliderTitle" />,
        caption: <TranslateStatic string="sliderCaption" />,
      },
    {
        key: 7,
        image: 'https://gelecektupbebek.com.tr/eski/Egitime-Destek-Projemiz/Zonguldak/img/1.JPG',
        title: <TranslateStatic string="sliderTitle" />,
        caption: <TranslateStatic string="sliderCaption" />,
      },
      {
        key: 8,
        image: 'https://gelecektupbebek.com.tr/eski/Egitime-Destek-Projemiz/Yozgat/img/1.jpg',
        title: <TranslateStatic string="sliderTitle" />,
        caption: <TranslateStatic string="sliderCaption" />,
      },
    {
        key: 9,
        image: 'https://gelecektupbebek.com.tr/eski/Egitime-Destek-Projemiz/Yozgat/img/2.jpg',
        title: <TranslateStatic string="sliderTitle" />,
        caption: <TranslateStatic string="sliderCaption" />,
      },
    {
        key: 10,
        image: 'https://gelecektupbebek.com.tr/eski/Egitime-Destek-Projemiz/Yozgat/img/4.jpg',
        title: <TranslateStatic string="sliderTitle" />,
        caption: <TranslateStatic string="sliderCaption" />,
      },
      {
        key: 11,
        image: 'https://gelecektupbebek.com.tr/eski/Egitime-Destek-Projemiz/Tunceli/img/1.JPG',
        title: <TranslateStatic string="sliderTitle" />,
        caption: <TranslateStatic string="sliderCaption" />,
      },
      {
          key: 12,
          image: 'https://gelecektupbebek.com.tr/eski/Egitime-Destek-Projemiz/Tunceli/img/2.JPG',
          title: <TranslateStatic string="sliderTitle" />,
          caption: <TranslateStatic string="sliderCaption" />,
        },
      {
          key: 13,
          image: 'https://gelecektupbebek.com.tr/eski/Egitime-Destek-Projemiz/Tunceli/img/3.JPG',
          title: <TranslateStatic string="sliderTitle" />,
          caption: <TranslateStatic string="sliderCaption" />,
        },
      {
          key: 14,
          image: 'https://gelecektupbebek.com.tr/eski/Egitime-Destek-Projemiz/Tunceli/img/4.JPG',
          title: <TranslateStatic string="sliderTitle" />,
          caption: <TranslateStatic string="sliderCaption" />,
        },
        {
          key: 15,
          image: 'https://gelecektupbebek.com.tr/eski/Egitime-Destek-Projemiz/Tunceli/img/5.JPG',
          title: <TranslateStatic string="sliderTitle" />,
          caption: <TranslateStatic string="sliderCaption" />,
        },
      {
          key: 16,
          image: 'https://gelecektupbebek.com.tr/eski/Egitime-Destek-Projemiz/Tunceli/img/6.JPG',
          title: <TranslateStatic string="sliderTitle" />,
          caption: <TranslateStatic string="sliderCaption" />,
        },
      {
          key: 17,
          image: 'https://gelecektupbebek.com.tr/eski/Egitime-Destek-Projemiz/Tunceli/img/7.JPG',
          title: <TranslateStatic string="sliderTitle" />,
          caption: <TranslateStatic string="sliderCaption" />,
        },
  ]
};

function slid() {

  return <Carousel {...carouselData} />
}

export default slid