
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
import { LocaleContext } from '../../locale-context';

import Harita from './harita';
export function EgitimeDestek() {

  return (
    <S.Wrapper>
      <S.Section>
        <h2><TranslateStatic string="EgitimDestek" /></h2>
        
        <div class="il-isimleri"></div>
        <Harita/>
        <div className='infoBar'>
          <div className='mavi'></div>
          <span><TranslateStatic string="DestekVerdigimiziller" />
        </span>
        </div>
        <div className='burs'>
          <h3><TranslateStatic string="KitapUzunBaslik" /></h3>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th><TranslateStatic string="Sehir" /></th>
                <th><TranslateStatic string="Okul" /></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>ARDAHAN</td>
                <td>GÖLBELEN İLKOKULU</td>
              </tr>
              <tr>
                <td>2</td>
                <td>ANTALYA/MANAVGAT</td>
                <td>BELENOBAŞI İLKOKULU</td>
              </tr>
              <tr>
                <td>3</td>
                <td>YOZGAT</td>
                <td>GÜLŞEHRİ ORTAOKULU</td>
              </tr>
              <tr>
                <td>4</td>
                <td>ANTALYA&nbsp;</td>
                <td>VARSAK ŞELALE ORTAOKULU</td>
              </tr>
              <tr>
                <td>5</td>
                <td>MERSİN</td>
                <td>ANAFARTALAR İLKOKULU</td>
              </tr>
              <tr>
                <td>6</td>
                <td>MUĞLA</td>
                <td>AHMET İMLİCE ZORLU İLKOKULU</td>
              </tr>
              <tr>
                <td>7</td>
                <td>GİRESUN</td>
                <td>KÖPRÜBAŞI İLKOKULU</td>
              </tr>
              <tr>
                <td>8</td>
                <td>KONYA</td>
                <td>AVDAN ORTAOKULU</td>
              </tr>
              <tr>
                <td>9</td>
                <td>ANKARA</td>
                <td>MAMAK ÇOCUKSEVENLER İLKOKULU</td>
              </tr>
              <tr>
                <td>10</td>
                <td>İZMİR</td>
                <td>ESİN ÇAĞDAŞ İLKOKULU</td>
              </tr>
              <tr>
                <td>11</td>
                <td>MANAVGAT</td>
                <td>TGB ETKİNLİĞİ</td>
              </tr>
              <tr>
                <td>12</td>
                <td>ISPARTA</td>
                <td>YALVAÇ ÖZBAYAT İLKOKULU</td>
              </tr>
              <tr>
                <td>13</td>
                <td>KÜTAHYA</td>
                <td>SİMAV BEYCE ORTAOKULU</td>
              </tr>
              <tr>
                <td>14</td>
                <td>ESKİŞEHİR</td>
                <td>TEPE BAŞI SEKİ ÖREN İLKOKULU</td>
              </tr>
              <tr>
                <td>15</td>
                <td>EDİRNE</td>
                <td>UZUN KÖPRÜ KAVACIK&nbsp;&nbsp;İLKOKULU</td>
              </tr>
              <tr>
                <td>16</td>
                <td>TRABZON</td>
                <td>DOĞANCI İLKOKULU</td>
              </tr>
              <tr>
                <td>17</td>
                <td>SAMSUN</td>
                <td>ASARCIK GÖKGÖL ŞEHİT MAHİR EMEN İLKOKULU</td>
              </tr>
              <tr>
                <td>18</td>
                <td>ZONGULDAK</td>
                <td>GÖKÇEBEY BAKİLER İLKOKULU</td>
              </tr>
              <tr>
                <td>19</td>
                <td>ORDU</td>
                <td>ŞEHİT HARUN MAYTALMAN İLKOKULU</td>
              </tr>
              <tr>
                <td>20</td>
                <td>AMASYA</td>
                <td>ÜLKÜ İLKOKULU</td>
              </tr>
              <tr>
                <td>21</td>
                <td>DENİZLİ</td>
                <td>BELENKÖY İLKÖĞRETİM OKULU</td>
              </tr>
              <tr>
                <td>22</td>
                <td>ŞIRNAK</td>
                <td>ŞEHİTLER MEHMET ALİ KÜLTÜR OKULU</td>
              </tr>
              <tr>
                <td>23</td>
                <td>IĞDIR</td>
                <td>DOĞANYURT İLKOKULU</td>
              </tr>
              <tr>
                <td>24</td>
                <td>NEVŞEHİR</td>
                <td>ACIGÖL ATATÜRK İLKOKULU</td>
              </tr>
              <tr>
                <td>25</td>
                <td>BOLU</td>
                <td>DÖRT DİVAN KARGI BAYRAMLAR İLKOKULU</td>
              </tr>
              <tr>
                <td>26</td>
                <td>BURDUR</td>
                <td>TEFENNİ BAŞPINAR İLKOKULU</td>
              </tr>
              <tr>
                <td>27</td>
                <td>KAYSERİ</td>
                <td>ŞEHİT JANDARMA YARBAY BEKİL TEMEL İLKOKULU</td>
              </tr>
              <tr>
                <td>28</td>
                <td>KOCAELİ</td>
                <td>KANDIRA SAFALI İLKOKULU</td>
              </tr>
              <tr>
                <td>29</td>
                <td>BALIKESİR</td>
                <td>YEŞİLDERE İLKOKULU</td>
              </tr>
              <tr>
                <td>30</td>
                <td>AFYON</td>
                <td>İNLİ İLKOKULU</td>
              </tr>
              <tr>
                <td>31</td>
                <td>MALATYA</td>
                <td>AYVALI İLKOKULU</td>
              </tr>
              <tr>
                <td>32</td>
                <td>SİVAS</td>
                <td>İSTİKLAL İLKOKULU</td>
              </tr>
              <tr>
                <td>33</td>
                <td>ÇANAKKALE</td>
                <td>HÜSEYİN AKİF TERZİOĞLU İLKOKULU</td>
              </tr>
              <tr>
                <td>34</td>
                <td>HATAY</td>
                <td>ERZİN YONCADÜZÜ İLKOKULU</td>
              </tr>
              <tr>
                <td>35</td>
                <td>VAN</td>
                <td>ERCİŞ ÇAKIRBEY İLKOKULU</td>
              </tr>
              <tr>
                <td>36</td>
                <td>ERZURUM</td>
                <td>HORASAN FEHMİ BİLGE İLKOKULU</td>
              </tr>
              <tr>
                <td>37</td>
                <td>KAHRAMANMARAŞ</td>
                <td>EŞE VE YUSUF GÜLKAYNAK İLKOKULU</td>
              </tr>
              <tr>
                <td>38</td>
                <td>AĞRI</td>
                <td>ELEŞKİRT SALKIMLI KÖYÜ İLKOKULU</td>
              </tr>
              <tr>
                <td>39</td>
                <td>TUNCELİ</td>
                <td>MAZGİRT AKPAZAR İLKOKULU</td>
              </tr>
              <tr>
                <td>40</td>
                <td>NİĞDE</td>
                <td>YENİYILDIZ İLKOKULU</td>
              </tr>
              <tr>
                <td>41</td>
                <td>DİYARBAKIR</td>
                <td>DORUK İLKOKULU</td>
              </tr>
              <tr>
                <td>42</td>
                <td>KİLİS</td>
                <td>YEDİGÖZ İLKOKULU</td>
              </tr>
              <tr>
                <td>43</td>
                <td>VAN</td>
                <td>YAHYA KEMAL BEYATLI İLKOKULU</td>
              </tr>
              <tr>
                <td>44</td>
                <td>KASTAMONU</td>
                <td>ALATARLA İLKOKULU</td>
              </tr>
              <tr>
                <td>45</td>
                <td>MARDİN MİDYAT</td>
                <td>FAHRETTİN ÖNEN İLKOKULU</td>
              </tr>
              <tr>
                <td>46</td>
                <td>BATMAN&nbsp;</td>
                <td>YEŞİLOBA İLKOKULU</td>
              </tr>
              <tr>
                <td>47</td>
                <td>ELAZIĞ</td>
                <td>MAHİR ÖZDEMİR İLKOKULU</td>
              </tr>
              <tr>
                <td>48</td>
                <td>AKSARAY</td>
                <td>ORTAKÖY FATİH İLKOKULU</td>
              </tr>
              <tr>
                <td>49</td>
                <td>SİNOP&nbsp;</td>
                <td>SARIKADI İLKOKULU</td>
              </tr>
              <tr>
                <td>50</td>
                <td>KONYA</td>
                <td>SADIKHACI İLKOKULU</td>
              </tr>
              <tr>
                <td>51</td>
                <td>KARS(KAĞIZMAN)</td>
                <td>PASLI ESİN ÇAĞDAŞ İLK-ORTAOKULU</td>
              </tr>
              <tr>
                <td>52</td>
                <td>ŞANLIURFA&nbsp;</td>
                <td>ŞENOCAK ORTAOKULU</td>
              </tr>
              <tr>
                <td>53</td>
                <td>OSMANİYE</td>
                <td>KARACAOĞLAN ORTAOKULU</td>
              </tr>
              <tr>
                <td>54</td>
                <td>ADIYAMAN</td>
                <td>YAZBAŞI ORTAOKULU</td>
              </tr>
              <tr>
                <td>55</td>
                <td>ARTVİN</td>
                <td>KEMAL PAŞA ORTAOKULU</td>
              </tr>
              <tr>
                <td>56</td>
                <td>BAYBURT</td>
                <td>BAYBURT ORTAOKULU</td>
              </tr>
              <tr>
                <td>57</td>
                <td>RİZE</td>
                <td>ATATÜRK ORTAOKULU</td>
              </tr>
              <tr>
                <td>58</td>
                <td>DENİZLİ/TAVAS</td>
                <td>YAHŞİLER İLKÖĞRETİM / ORTAOKULU</td>
              </tr>
              <tr>
                <td>59</td>
                <td>AYDIN</td>
                <td>GÖLHİSAR KÖYÜ İLKOKULU</td>
              </tr>
              <tr>
                <td>60</td>
                <td>HAKKARİ</td>
                <td>GELİŞEN KÖYÜ KÜTÜKLÜ MEZRASI İLKOKULU</td>
              </tr>
              <tr>
                <td>61</td>
                <td>ADANA/SARIÇAM</td>
                <td>AVCILAR İLKOKULU</td>
              </tr>
              <tr>
                <td>62</td>
                <td>ERZİNCAN</td>
                <td>TÜRK TELEKOM BİNALİ YILDIRIM İLKOKULU</td>
              </tr>
              <tr>
                <td>63</td>
                <td>BARTIN</td>
                <td>AHMETLER İLKOKULU</td>
              </tr>
              <tr>
                <td>64</td>
                <td>GÜMÜŞHANE</td>
                <td>GÜMÜŞGÖZÜ İLKOKULU</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='burs'>
          <h3><TranslateStatic string="OgrenciBurs" /></h3>
          <table >
            <thead>
              <tr>
                <th><TranslateStatic string="Tarih" /></th>
                <th><TranslateStatic string="EgitimKurumu" /></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>2005/2010</td>
                <td>İstanbul Marmara Ünv. Resim İş Öğretmenliği</td>
              </tr>
              <tr>
                <td>2007/2010</td>
                <td>Sakarya Ünv. Makina Mühendisliği</td>
              </tr>
              <tr>
                <td>2012/2013</td>
                <td>İstanbul Ünv. Fizik Tedavi ve Rehabilitasyon </td>
              </tr>
              <tr>
                <td>2012/2013</td>
                <td>Ankara Hacetepe Ünv. Tıp Fakültesi </td>
              </tr>
              <tr>
                <td>2012/2013</td>
                <td>Karabük Atatürk Anadolu Lisesi </td>
              </tr>
              <tr>
                <td>2012/2013</td>
                <td>Erzurum Horasan Anadolu Lisesi</td>
              </tr>
              <tr>
                <td>2012/2013 </td>
                <td>Bingöl Yayladere Çok Amaçlı Lisesi </td>
              </tr>
              <tr>
                <td>2012/2013 </td>
                <td>Amasya Türk Telekom Teknik ve Endüstri Meslek Lisesi</td>
              </tr>
              <tr>
                <td>2012-2013</td>
                <td>Kırıkkale Yahya Kemal Beyatlı Mes. ve Tek. Anadolu Lisesi </td>
              </tr>
              <tr>
                <td>2013/2014</td>
                <td>İzmir Ekonomi Ünv. Sağ. Meslek Yüksek Okulu </td>
              </tr>
              <tr>
                <td>2014/2015</td>
                <td>Ankara Haccetepe Ünv. Tıp Fakültesi </td>
              </tr>
              <tr>
                <td>2014/2015 </td>
                <td>Ankara Orta Doğu Tek. Ünv. Müh. Fakültesi </td>
              </tr>
              <tr>
                <td>2014/2015</td>
                <td>Bursa Uludağ Ünv. İktisat ve İdari Bilimleri Fakültesi </td>
              </tr>
              <tr>
                <td>2016/2018 </td>
                <td>Akdeniz Ünv. Tıp Fakültesi </td>
              </tr>
              <tr>
                <td>2017/2019 </td>
                <td>Özbey Ortadoğu Teknik Ünv. Müh. Fakültesi </td>
              </tr>
              <tr>
                <td>2017/2019 </td>
                <td>Uludağ Ünv. (İ.B.F ) Maliye </td>
              </tr>
              <tr>
                <td>2017/2019 </td>
                <td>Afyon Kocatepe Ünv. Öğretmenliği  </td>
              </tr>
              <tr>
                <td>2017/2019  </td>
                <td>Cumhuriyet Ünv. Sağlık Bilimleri Fakültesi   </td>
              </tr>
              <tr>
                <td>2017/2019 </td>
                <td>Bursa Uludağ Ünv. Fen-Edebiyat Fakültesi   </td>
              </tr>
              <tr>
                <td>2019/2021 </td>
                <td>Antalya/Akdeniz Ünv. Edebiyat Fakültesi  </td>
              </tr>
              <tr>
                <td>2019/2021 </td>
                <td>Antalya Akdeniz Üniversitesi Siyasi Bilimler    </td>
              </tr>
              <tr>
                <td>2019/2021 </td>
                <td>Antalya / Manavgat Akdeniz Üniversitesi   </td>
              </tr>
              <tr>
                <td>2019/2021 </td>
                <td>Antalya Akdeniz Üniversitesi Siyasi Bilimler   </td>
              </tr>
              <tr>
                <td>2019/2021 </td>
                <td>Denizli Pamukkale Ünv.Tıp Fakültesi   </td>
              </tr>
            </tbody>
          </table>
        </div>

      </S.Section>
    </S.Wrapper>
  );
}
