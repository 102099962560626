import React from 'react'
import { LangChangerSC, OptionV } from './style'
import Translate from '../../../locales/TranslateStatic';
import { LocaleContext } from '../../../locale-context';
import TR from '../../../assets/turkeyflag.png'
import EN from '../../../assets/kingdomflag.png'
export default function LanguagePicker(props) {

    const { changeLanguage, lang } = props
    return (<LocaleContext.Consumer>
        {(value) => {
            if (value === "en") {
                return <LangChangerSC value={lang} onChange={(e) => changeLanguage(e.target.value)}>
                    <OptionV imageurl={EN} id="tr" value="tr" > Turkish</OptionV>
                    <OptionV imageurl={EN} id="en" value="en" >English</OptionV>
                    <OptionV imageurl={EN} id="ru" value="ru" >Русский</OptionV>
                    <OptionV imageurl={EN} id="ar" value="ar" >Arabic</OptionV>
                    <OptionV imageurl={EN} id="uz" value="uz" >Uzbek</OptionV>
                    {/* <option id="ru" value="ru" >🇷🇺</option> */}
                </LangChangerSC>
            } else if (value === 'tr') {
                return <LangChangerSC value={lang} onChange={(e) => changeLanguage(e.target.value)}>

                    {/* <OptionV imageurl="https://img.icons8.com/?size=100&id=15541&format=png&color=000000" id="tr" value="tr" ><img src={TR} /></OptionV> */}
                    <OptionV imageurl={EN} id="tr" value="tr" > Turkish</OptionV>
                    <OptionV imageurl={EN} id="en" value="en" >English</OptionV>
                    <OptionV imageurl={EN} id="ru" value="ru" >Русский</OptionV>
                    <OptionV imageurl={EN} id="ar" value="ar" >Arabic</OptionV>
                    <OptionV imageurl={EN} id="uz" value="uz" >Uzbek</OptionV>
                    {/* <option id="ru" value="ru" >🇷🇺</option> */}
                </LangChangerSC>
            }
            else if (value === 'ru') {
                return <LangChangerSC value={props.lang} onChange={(e) => changeLanguage(e.target.value)}>
                    <OptionV imageurl={EN} id="tr" value="tr" > Turkish</OptionV>
                    <OptionV imageurl={EN} id="en" value="en" >English</OptionV>
                    <OptionV imageurl={EN} id="ru" value="ru" >Русский</OptionV>
                    <OptionV imageurl={EN} id="ar" value="ar" >Arabic</OptionV>
                    <OptionV imageurl={EN} id="uz" value="uz" >Uzbek</OptionV>
                </LangChangerSC>
            }
            else if (value === 'de') {
                return <LangChangerSC value={props.lang} onChange={(e) => changeLanguage(e.target.value)}>
                    <OptionV imageurl={EN} id="tr" value="tr" > Turkish</OptionV>
                    <OptionV imageurl={EN} id="en" value="en" >English</OptionV>
                    <OptionV imageurl={EN} id="ru" value="ru" >Русский</OptionV>
                    <OptionV imageurl={EN} id="ar" value="ar" >Arabic</OptionV>
                    <OptionV imageurl={EN} id="uz" value="uz" >Uzbek</OptionV>
                </LangChangerSC>
            }
            else if (value === 'cz') {
                return <LangChangerSC value={props.lang} onChange={(e) => changeLanguage(e.target.value)}>
                    <OptionV imageurl={EN} id="tr" value="tr" > Turkish</OptionV>
                    <OptionV imageurl={EN} id="en" value="en" >English</OptionV>
                    <OptionV imageurl={EN} id="ru" value="ru" >Русский</OptionV>
                    <OptionV imageurl={EN} id="ar" value="ar" >Arabic</OptionV>
                    <OptionV imageurl={EN} id="uz" value="uz" >Uzbek</OptionV>
                </LangChangerSC>
            }
            else if (value === 'uz') {
                return <LangChangerSC value={props.lang} onChange={(e) => changeLanguage(e.target.value)}>
                    <OptionV imageurl={EN} id="tr" value="tr" > Turkish</OptionV>
                    <OptionV imageurl={EN} id="en" value="en" >English</OptionV>
                    <OptionV imageurl={EN} id="ru" value="ru" >Русский</OptionV>
                    <OptionV imageurl={EN} id="ar" value="ar" >Arabic</OptionV>
                    <OptionV imageurl={EN} id="uz" value="uz" >Uzbek</OptionV>
                </LangChangerSC>
            }
            else if (value === 'ar') {
                return <LangChangerSC value={props.lang} onChange={(e) => changeLanguage(e.target.value)}>
                    <OptionV imageurl={EN} id="tr" value="tr" > Turkish</OptionV>
                    <OptionV imageurl={EN} id="en" value="en" >English</OptionV>
                    <OptionV imageurl={EN} id="ru" value="ru" >Русский</OptionV>
                    <OptionV imageurl={EN} id="ar" value="ar" >Arabic</OptionV>
                    <OptionV imageurl={EN} id="uz" value="uz" >Uzbek</OptionV>
                </LangChangerSC>
            }
        }}
    </LocaleContext.Consumer>
    )



}